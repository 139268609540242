export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.ico"}],"style":[],"script":[{"innerHTML":"\n      function validateBrowser() {\n        if (window.location.pathname.indexOf('/unsupported-browser') >= 0) {\n          return\n        }\n        \n        var browserList = /Edge?\\/([80]\\d|\\d{3,})\\.\\d+(\\.\\d+|)|Firefox\\/(7[89]|[8-9]\\d|\\d{3,})\\.\\d+(\\.\\d+|)|Chrom(ium|e)\\/([80]\\d|\\d{3,})\\.\\d+(\\.\\d+|)|(Maci|X1{2}).+ Version\\/(1[4-9]|[2-9]\\d|\\d{3,})\\.\\d+([,.]\\d+|)( \\(\\w+\\)|)( Mobile\\/\\w+|) Safari\\/|Chrome.+OPR\\/(6[7-9]|[7-9]\\d|\\d{3,})\\.\\d+\\.\\d+|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(14[._]\\d+|(1[5-9]|[2-9]\\d|\\d{3,})[._]\\d+)([._]\\d+|)|Android.+Firefox\\/(13\\d|1[4-9]\\d|[2-9]\\d{2}|\\d{4,})\\.\\d+(\\.\\d+|)|Android.+Chrom(ium|e)\\/(13\\d|1[4-9]\\d|[2-9]\\d{2}|\\d{4,})\\.\\d+(\\.\\d+|)|SamsungBrowser\\/(1[2-9]|[2-9]\\d|\\d{3,})\\.\\d+/;\n        var userAgent = '';\n\n        if (window.navigator && window.navigator.userAgent) {\n          userAgent = window.navigator.userAgent;\n        }\n\n        if (browserList.test(userAgent)) {\n          return  \n        }\n\n        var locale = window.location.pathname.split('/').filter(function (el) {\n          return el.length;\n        })[0];\n        \n        if (locale.length === 2) {\n          locale = '/' + locale;\n        } else {\n          locale = '';\n        }\n        \n        window.location.href = locale + '/unsupported-browser';\n      }\n\n      validateBrowser();\n    ","tagPosition":"head","tagPriority":"critical"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1, shrink-to-fit=no","title":"BMW Group","bodyAttrs":{"class":"font-sans theme-bmw","data-theme":"bmwgroup"}}

export const appPageTransition = false

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false